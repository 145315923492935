import { abilitiesPlugin } from "@casl/vue";
import ability from "../services/Ability";
import { boot } from "quasar/wrappers";

export default boot(({ app }) => {

  app.use(abilitiesPlugin, ability);

  app.config.globalProperties.$ability = ability
});

export { ability };
