
import { computed, defineComponent, watch } from "vue";
import { provideApolloClient } from "@vue/apollo-composable"
import { apolloClients } from "src/extensions/apollo/boot"
import { useQuasar } from "quasar";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "App",
  setup() {
    provideApolloClient(apolloClients.default)

    const $route = useRoute();
    const $q = useQuasar();
    const { t: $t } = useI18n();

    const permissionDenied = computed(() => {
      return $route.query.permission_denied;
    })

    watch(permissionDenied, () => {
      $q.notify({
        message: $t("globals.permission_denied"),
        color: "negative",
        icon: "warning"
      })
    })

    return {}
  },
})
