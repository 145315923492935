import { SiteStateInterface } from "src/store/site/types";

export default function (): SiteStateInterface {
  return {
    language: "sl-SI",
    filters: {},
    defaultFilters: {
      index_career_jobs: [
        {
          column: "organization",
          operator: "IN",
          value: [],
          unClearable: true,
          ignoreEmpty: true
        }, {
          column: "region",
          operator: "IN",
          value: [],
          unClearable: true,
          ignoreEmpty: true
        }, {
          column: "careerJobType",
          operator: "IN",
          value: [],
          unClearable: true,
          ignoreEmpty: true
        },
        {
          column: "date_apply",
          operator: "BETWEEN",
          value: [],
          unClearable: true,
          ignoreEmpty: true
        },
        {
          column: "date_start",
          operator: "BETWEEN",
          value: [],
          unClearable: true,
          ignoreEmpty: true
        },
      ],
      index_courses: [
        {
          column: "schools",
          operator: "IN",
          value: [],
          unClearable: true,
          ignoreEmpty: true
        }, {
          column: "topic",
          operator: "IN",
          value: [],
          unClearable: true,
          ignoreEmpty: true
        }, {
          column: "contractor",
          operator: "IN",
          value: [],
          unClearable: true,
          ignoreEmpty: true
        }, {
          column: "status",
          operator: "IN",
          value: [],
          unClearable: true,
          ignoreEmpty: true
        },
        {
          column: "date_start",
          operator: "BETWEEN",
          value: [],
          unClearable: true,
          ignoreEmpty: true
        },
        {
          column: "online",
          operator: "EQ",
          value: null,
          unClearable: true,
          ignoreEmpty: true
        }
      ]
    },
    visibleColumns: {},
    defaultVisibleColumns: {},
    sortColumns: {},
    defaultSortColumns: {},
    rowsPerPage: {},
    forms: {
      generic: {
        permissions: true
      },
      evidenca_statistics: {
        permissions: true
      },
      filter: {
        permissions: true
      },
      register: {
        permissions: true
      },
      forgot_password: {
        permissions: true
      },
      completed_course: {
        permissions: true
      },
      profile: {
        permissions: true
      },
      change_own_password: {
        permissions: true
      },
      mentor_report: {
        permissions: {
          action: "update",
          model: "internship_agreements",
          field: "mentor_report",
        },
      },
      student_report: {
        permissions: {
          action: "update",
          model: "internship_agreements",
          field: "student_report",
        },
      },
      career_jobs: {
        permissions: {
          action: "create",
          model: "career_jobs",
        },
      },
      topics_suggest: {
        permissions: {
          action: "suggest",
          model: "topics",
        },
      },
      organizations_suggest: {
        permissions: {
          action: "suggest",
          model: "organizations",
        },
      }
    }
  }
}
