import { AuthStateInterface, UserStateInterface } from "src/store/auth/types";
import { FilterInterface, SavedFilterInterface } from "src/store/types";
import { uid } from "quasar";

export function setToken(state: AuthStateInterface, token: string) {
  state.token = token
}

export function setUser(state: AuthStateInterface, user: UserStateInterface) {
  state.user = user
}

export function saveFilter(
  state: AuthStateInterface,
  {
    filterKey,
    filter,
    name,
    visibleColumns,
    sortColumns
  }: { filterKey: string, filter: FilterInterface[], name: string, visibleColumns: string[], sortColumns: string[] }
) {
  if (!state.userSettings.filters[filterKey]) {
    state.userSettings.filters[filterKey] = [];
  }

  const filterObject = {
    filterId: uid(),
    name: name,
    filter: filter,
    visibleColumns: visibleColumns,
    sortColumns: sortColumns,
  }

  state.userSettings.filters[filterKey].push(filterObject);
}

export function saveFilters(
  state: AuthStateInterface,
  {
    filterKey,
    filters,
  }: { filterKey: string, filters: SavedFilterInterface[] }
) {
  state.userSettings.filters[filterKey] = filters;
}

export function deleteFilter(
  state: AuthStateInterface,
  { filterKey, filterId }: { filterKey: string, filterId: string }
) {
  if (!state.userSettings.filters[filterKey]) {
    return;
  }

  const filterIndex = state.userSettings.filters[filterKey].findIndex(userFilter => userFilter.filterId === filterId)

  state.userSettings.filters[filterKey].splice(filterIndex, 1);
}
