export default {
  "en-US": {
    short: {
      year: "numeric", month: "short", day: "numeric"
    },
    long: {
      year: "numeric", month: "short", day: "numeric",
      weekday: "short", hour: "numeric", minute: "numeric"
    }
  },
  "si-SI": {
    short: {
      year: "numeric", month: "short", day: "numeric"
    },
    long: {
      year: "numeric", month: "numeric", day: "numeric",
      hour: "numeric", minute: "numeric",
    }
  }
}
