import { Commit } from "vuex";
import { FilterInterface } from "src/store/types";

export function setLanguage({ commit }: { commit: Commit }, payload: string) {
  commit("setLanguage", payload)
}

export function setFilters({ commit }: { commit: Commit }, payload: { filterKey: string, filters: FilterInterface[] }) {
  commit("setFilters", payload)
}

export function setDefaultFilters(
  { commit }: { commit: Commit },
  payload: { filterKey: string, filters: FilterInterface[] }
) {
  commit("setDefaultFilters", payload)
}

export function setVisibleColumns(
  { commit }: { commit: Commit },
  payload: { filterKey: string, visibleColumns: string[] }
) {
  commit("setVisibleColumns", payload)
}

export function setDefaultVisibleColumns(
  { commit }: { commit: Commit },
  payload: { filterKey: string, visibleColumns: string[] }
) {
  commit("setDefaultVisibleColumns", payload)
}

export function setSortColumns(
  { commit }: { commit: Commit },
  payload: { filterKey: string, sortColumns: string[] }
) {
  commit("setSortColumns", payload)
}

export function setDefaultSortColumns(
  { commit }: { commit: Commit },
  payload: { filterKey: string, sortColumns: string[] }
) {
  commit("setDefaultSortColumns", payload)
}

export function setRowsPerPage(
  { commit }: { commit: Commit },
  payload: { filterKey: string, rowsPerPage: number }
) {
  commit("setRowsPerPage", payload)
}
