import { createStore, Store as VuexStore } from "vuex"
import { StateInterface } from "src/store/types";
import auth from "./auth";
import site from "./site";
import createPersistedState from "vuex-persistedstate";

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

// provide typings for `this.$store`
declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $store: VuexStore<StateInterface>
  }
}

export default function (/* { ssrContext } */) {
  return createStore({
    modules: {
      auth,
      site
    },
    plugins: [createPersistedState({
      paths: ["auth", "site.language", "site.filters", "site.visibleColumns", "site.sortColumns", "site.rowsPerPage"]
    })],

    strict: !!process.env.DEBUGGING
  })
}
