import { RouteRecordRaw } from "vue-router";

const routesCareerCenter: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/IndexCareerCenter.vue")
      },
    ],
    meta: {
      requireAuth: true,
      permission: null,
    }
  },
  {
    path: "/career-jobs",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/career-jobs/index.vue")
      },
      {
        path: "/career-jobs/create",
        component: () => import("pages/career-jobs/view.vue"),
        meta: {
          permission: {
            action: "create",
            model: "career_jobs"
          }
        }
      },
      {
        path: "/career-jobs/:id",
        component: () => import("pages/career-jobs/view.vue"),
        props: true,
        meta: {
          permission: {
            action: "view",
            model: "career_jobs"
          }
        }
      },
      {
        path: "/career-jobs/:id/duplicate",
        component: () => import("pages/career-jobs/view.vue"),
        props: { duplicate: true },
        meta: {
          permission: {
            action: "create",
            model: "career_jobs"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "career_jobs"
      }
    }
  },
  {
    path: "/career-job-types",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/career-job-types/index.vue")
      },
      {
        path: "/career-job-types/create",
        component: () => import("pages/career-job-types/view.vue"),
        meta: {

          permission: {
            action: "create",
            model: "career_job_types"
          }
        }
      },
      {
        path: "/career-job-types/:id",
        component: () => import("pages/career-job-types/view.vue"),
        props: true,
        meta: {

          permission: {
            action: "view",
            model: "career_job_types"
          }
        }
      },
      {
        path: "/career-job-types/:id/duplicate",
        component: () => import("pages/career-job-types/view.vue"),
        props: { duplicate: true },
        meta: {
          permission: {
            action: "create",
            model: "career_job_types"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "career_job_types"
      }
    }
  },
  {
    path: "/organizations",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/organizations/index.vue")
      },
      {
        path: "/organizations/create",
        component: () => import("pages/organizations/view.vue"),
        meta: {
          permission: {
            action: "create",
            model: "organizations"
          }
        }
      },
      {
        path: "/organizations/:id",
        component: () => import("pages/organizations/view.vue"),
        props: true,
        meta: {
          permission: {
            action: "view",
            model: "organizations"
          }
        }
      },
      {
        path: "/organizations/:id/duplicate",
        component: () => import("pages/organizations/view.vue"),
        props: { duplicate: true },
        meta: {
          permission: {
            action: "create",
            model: "organizations"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "organizations"
      }
    }
  },

  {
    path: "/schools",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/schools/index.vue")
      },
      {
        path: "/schools/create",
        component: () => import("pages/schools/view.vue"),
        meta: {
          permission: {
            action: "create",
            model: "schools"
          }
        }
      },
      {
        path: "/schools/:id",
        component: () => import("pages/schools/view.vue"),
        props: true,
        meta: {

          permission: {
            action: "view",
            model: "schools"
          }
        }
      },
      {
        path: "/schools/:id/duplicate",
        component: () => import("pages/schools/view.vue"),
        props: { duplicate: true },
        meta: {

          permission: {
            action: "create",
            model: "schools"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "schools"
      }
    }
  },

  {
    path: "/register",
    name: "register",
    component: () => import("layouts/EmptyLayout.vue"),
    children: [{
      path: "",
      component: () => import("pages/Register.vue")
    }],
  },
  {
    path: "/email-verify",
    name: "email-verify",
    component: () => import("layouts/EmptyLayout.vue"),
    children: [{
      path: "",
      component: () => import("pages/VerifyEmail.vue")
    }],
  },
];

export default routesCareerCenter;
