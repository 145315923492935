import ability from "../services/Ability"
import { RawRule } from "@casl/ability";
import { PermissionInterface } from "src/models/users/Permission";
import { PermissionObjectInterface } from "src/hooks/UseMenu";

export default function useAbility() {
  const updateAbility = (permissions: PermissionInterface[]) => {

    const rules: RawRule[] = permissions.map(permission => {
      const splitPermission = permission.name.split("-");
      const rule: RawRule = {
        action: splitPermission[0],
        subject: splitPermission[1]

      }
      if (splitPermission[2]) {
        rule.fields = [splitPermission[2]]
      }
      return rule
    })
    // @ts-ignore
    ability.update(rules)
  }
  const permissionAsString = (permission: PermissionObjectInterface) => {
    const permissionParts = [permission.action, permission.model];
    if (permission.field) {
      permissionParts.push(permission.field);
    }
    return permissionParts.join("-")
  }
  return {
    updateAbility,
    ability,
    permissionAsString
  }
}
