import { RouteRecordRaw } from "vue-router";

const routesCommon: RouteRecordRaw[] = [
  {
    path: "/profile",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/users/profile.vue")
      },
    ],
    meta: {
      requireAuth: true,
      permission: null,
    }
  },
  {
    path: "/notifications",
    component: () => import("layouts/MainLayout.vue"),
    children: [

      {
        path: "",
        component: () => import("pages/Notifications.vue")
      },
    ],
    meta: {
      requireAuth: true,
      permission: null,
    }
  },
  {
    path: "/manual",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/Manual.vue")
      },
    ],
    meta: {
      requireAuth: true,
      permission: null,
    }
  },
  {
    path: "/manual-print",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/ManualPrint.vue")
      },
    ],
    meta: {
      requireAuth: true,
      permission: null,
    }
  },
  {
    path: "/settings",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/Settings.vue")
      },
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "update",
        model: "settings"
      },
    }
  },
  {
    path: "/users",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/users/index.vue"),
        meta: {
          permission: {
            action: "view",
            model: "users"
          }
        }
      },
      {
        path: "/users/create",
        component: () => import("pages/users/view.vue"),
        meta: {
          permission: {
            action: "create",
            model: "users"
          }
        }
      },
      {
        path: "/users/create/:role",
        component: () => import("pages/users/view.vue"),
        props: true,
        meta: {
          permission: {
            action: "create",
            model: "users"
          }
        }
      },
      {
        path: "/users/:id",
        component: () => import("pages/users/view.vue"),
        props: true
      },
      {
        path: "/profile/change-own-password",
        component: () => import("pages/users/change-own-password.vue"),
        meta: {
          requireAuth: true,
          permission: null,
        }
      },
      {
        path: "/users/change-password/:user_id",
        component: () => import("pages/users/change-password.vue"),
        meta: {
          permission: {
            action: "update",
            model: "users"
          }
        }
      },
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "users"
      }
    }
  },

  {
    path: "/education-levels",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/education-levels/index.vue")
      },
      {
        path: "/education-levels/create",
        component: () => import("pages/education-levels/view.vue"),
        meta: {

          permission: {
            action: "create",
            model: "education_levels"
          }
        }
      },
      {
        path: "/education-levels/:id",
        component: () => import("pages/education-levels/view.vue"),
        props: true,
        meta: {
          permission: {
            action: "view",
            model: "education_levels"
          }
        }
      },
      {
        path: "/education-levels/:id/duplicate",
        component: () => import("pages/education-levels/view.vue"),
        props: { duplicate: true },
        meta: {
          permission: {
            action: "create",
            model: "education_levels"
          }
        }
      },
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "education_levels"
      }
    }
  },
  {
    path: "/manuals",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/manuals/index.vue")
      },
      {
        path: "/manuals/create",
        component: () => import("pages/manuals/view.vue"),
        meta: {

          permission: {
            action: "create",
            model: "manuals"
          }
        }
      },
      {
        path: "/manuals/:id",
        component: () => import("pages/manuals/view.vue"),
        props: true,
        meta: {
          permission: {
            action: "view",
            model: "manuals"
          }
        }
      },
      {
        path: "/manuals/:id/duplicate",
        component: () => import("pages/manuals/view.vue"),
        props: { duplicate: true },
        meta: {
          permission: {
            action: "create",
            model: "manuals"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "manuals"
      }
    }
  },
  {
    path: "/print-templates/:table_name",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/print-templates/index.vue")
      },
      {
        path: "/print-templates/print/:table_name/:object_id/:id",
        component: () => import("pages/print-templates/print.vue"),
        props: true,
      },
      {
        path: "/print-templates/:table_name/create",
        component: () => import("pages/print-templates/view.vue"),
        meta: {

          permission: {
            action: "create",
            model: "education_levels"
          }
        }
      },
      {
        path: "/print-templates/:table_name/:id",
        component: () => import("pages/print-templates/view.vue"),
        props: true,
      },
      {
        path: "/print-templates/:table_name/:id/duplicate",
        component: () => import("pages/print-templates/view.vue"),
        props: { duplicate: true },
      },
    ],
  },
  {
    path: "/roles",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/roles/index.vue"),
        meta: {
          permission: {
            action: "view",
            model: "roles"
          }
        }
      },
      {
        path: "/roles/create",
        component: () => import("pages/roles/view.vue"),
        meta: {
          permission: {
            action: "create",
            model: "roles"
          }
        }
      },
      {
        path: "/roles/:id",
        component: () => import("pages/roles/view.vue"),
        props: true,
        meta: {
          permission: {
            action: "view",
            model: "roles"
          }
        }
      },
      {
        path: "/roles/:id/duplicate",
        component: () => import("pages/roles/view.vue"),
        props: { duplicate: true },
        meta: {
          permission: {
            action: "create",
            model: "roles"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "roles"
      }
    }
  },
  {
    path: "/permissions",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/permissions/index.vue"),
        meta: {
          permission: {
            action: "view",
            model: "permissions"
          }
        }
      },
      {
        path: "/permissions/create",
        component: () => import("pages/permissions/view.vue"),
        meta: {
          permission: {
            action: "create",
            model: "permissions"
          }
        }
      },
      {
        path: "/permissions/:id",
        component: () => import("pages/permissions/view.vue"),
        props: true,
        meta: {
          permission: {
            action: "view",
            model: "permissions"
          }
        }
      },
      {
        path: "/permissions/:id/duplicate",
        component: () => import("pages/permissions/view.vue"),
        props: { duplicate: true },
        meta: {
          permission: {
            action: "create",
            model: "permissions"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "permissions"
      }
    }
  },
  {
    path: "/regions",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/regions/index.vue")
      },
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "regions"
      }
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("layouts/EmptyLayout.vue"),
    children: [{
      path: "",
      component: () => import("pages/Login.vue")
    }],
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("layouts/EmptyLayout.vue"),
    children: [{
      path: "",
      component: () => import("pages/ForgotPassword.vue")
    }],
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("layouts/EmptyLayout.vue"),
    children: [{
      path: "",
      component: () => import("pages/ResetPassword.vue")
    }],
  },
  {
    path: "/jwt",
    name: "jwt",
    component: () => import("layouts/EmptyLayout.vue"),
    children: [{
      path: "",
      component: () => import("pages/JWT.vue")
    }],
  },
  {
    path: "/logout",
    name: "logout",
    component: {
      beforeRouteEnter(to, from, next) {

        next("login");
      }
    }
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("pages/Error404.vue"),
  },
];

export default routesCommon;
