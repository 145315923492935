// noinspection AllyPlainJsInspection
import statuses from "./statuses"

export default {
  menu: {
    dashboard: "Prva stran",
    users: "Uporabniki"
  },
  globals: {
    evidenca: "Evidenca izobraževanj",
    apprenticeship: "Apprenticeship track",
    career_center: "Iskalnik kariernih priložnosti",
    add: "Dodaj",
    search: "Iskanje",
    clear: "Počisti",
    total: "Skupaj",
    quick_search: "Hitro iskanje",
    details: "Podrobnosti",
    actions: "Akcije",
    next: "Naslednji",
    previous: "Prejšnji",
    done: "Končano",
    edit_mode_on: "Vklopi urejanje",
    edit_mode_off: "Izklopi urejanje",
    locked_off: "Odkleni",
    locked_on: "Zakleni",
    enable_disable_all: "Vklopi/Izklopi vse",
    enable_disable_this_group: "Vklopi/Izklopi to skupino",
    copy: "Kopiraj",
    insert_to_textarea: "Vstavi v urejevalnik",
    complete: "Končaj",
    cancel: "Prekliči",
    confirm: "Potrdi",
    print: "Tiskaj",
    print_view: "Pogled za tisk",
    back: "Nazaj",
    view: "Poglej",
    rate: "Oceni",
    edit: "Uredi",
    save: "Shrani",
    show_hide_columns: "Prikaži/skrij stolpce",
    no: "Ne",
    yet: "Še",
    vote: "Glasuj",
    apply: "Prijava",
    already_applied: "Že prijavljeni",
    interested: "Zanima me",
    already_interested: "Že zainteresirani",
    interested_are: "Zanimajo se",
    suggest: "Predlagaj",
    loading: "Nalaganje...",
    create: "Dodaj",
    delete: "Izbriši",
    duplicate: "Podvoji",
    new: "Novo",
    select_for_vote: "Izberi to temo",
    export: "Izvozi",
    import: "Uvozi",
    custom_export: "Izvoz po meri",
    choose_fields: "Izberite polja",
    filters: "Filtri",
    pass: "Opravljeno",
    passed_courses: "Opravljena izobraževanja",
    fail: "Neopravljeno",
    set_as_avatar: "Nastavi kot prikazno sliko",
    download: "Prenesi",
    fields: "Polja",
    confirm_delete: "Potrdi izbris",
    record_deleted: "Zapis izbrisan",
    record_not_deleted: "Zapisa ni mogoče izbrisati. Ta zapis je uporabljen v aplikaciji.",
    record_cloned: "Zapis podvojen",
    status_updated: "Status posodobljen",
    your_vote_was_successful: "Vaša izbira je uspela",
    application_successful: "Uspešno ste se prijavili",
    will_be_able_to_see: "Predstavnik podjetja vas bo lahko kontaktiral. Lahko bodo videli vaše ime, elektronski naslov in CV. Se želite prijaviti na delovno mesto?",
    set_as_cv: "Nastavi kot CV",
    set_as_pri_plan: "Nastavi kot načrt izvedbe PRI",
    pri_plan: "Načrt izvedbe PRI",
    files: "Datoteke",
    registration_successful: "Registracija je uspela. Potrditveni email je bil poslan na vaš e-naslov.",
    forgot_password_mail_send: "Na vaš e-naslov ste prejeli povezavo za ponastavitev gesla.",
    password_updated: "Vaše geslo je posodobljeno. Sedaj se lahko prijavite z novim geslom.",
    no_results: "Ni zadetkov",
    no_description: "Ni opisa",
    permission_denied: "Dostop zavrnjen.",
    close: "Zapri",
    refresh: "Osveži",
    saved: "Shranjeno",
    choose_template_article_to_copy_content_from: "Izberite predlogo členov iz katere so bodo prenesli členi",
    select_fields_for_export: "Izberite polja za izvoz",
    no_saved_filters: "Ni shranjenih filtrov",
    no_address: "Ni glavnega naslova",
    check_entered_data: "Preverite vnešene ali manjkajoče podatke!",
    page_not_found: "Stran ni najdena...",
    home: "Domov",
    your_interest_was_acknowledged: "Vaše zanimanje je zabeleženo. Podrobnosti si lahko ogledate v vašem profilu.",
    no_date: "Brez datuma",
    start_date: "Začetni datum",
    end_date: "Končni datum",
    change_password: "Zamenjajte geslo",
    updated: "Posodobljeno",
    export_xlsx: "Izvozi v Excel",
    export_csv: "Izvozi kot CSV",
    mask_as_user: "Prijavi se s tem uporabnikom",
    get_data_from_ajpes: "Pridobi podatke iz sistema Ajpes",
    enter_registration_number: "Vnesite matično številko podjetja",
    organization_found: "Organizacija najdena!",
    organization_not_found: "Organizacija ni najdena!",
    unlocked: "Zapis odklenjeno",
    locked: "Zapis zaklenjen",
    no_prints: "Ni dokumentov",
    copied_to_clipboard: "kopirano v odložišče",
    expand: "Razširi",
  },
  permissions: {
    actions: {
      select: "Izberi",
      view: "Poglej",
      create: "Dodaj",
      update: "Posodobi",
      delete: "Izbriši",
      unlock: "Odkleni",
      select_authorized: "Izberi pooblaščene",
      view_authorized: "Poglej pooblaščene",
      create_authorized: "Ustvari pooblaščene",
      update_authorized: "Posodobi pooblaščene",
      delete_authorized: "Izbriši pooblaščene",
      view_prints: "Poglej predloge tiska",
      create_prints: "Ustvari predlogo tiska",
      update_prints: "Posodobi predlogo tiska",
      delete_prints: "Izbriši predlogo tiska",
      view_authorized_prints: "Poglej predloge tiska pri pooblaščenih",
      create_authorized_prints: "Ustvari predlogo tiska pri pooblaščenih",
      update_authorized_prints: "Posodobi predlogo tiska pri pooblaščenih",
      delete_authorized_prints: "Izbriši predlogo tiska pri pooblaščenih",
      view_menu: "Prikaži meni",
      change_status: "Spremeni status",
      view_media: "Poglej datoteke",
      create_media: "Dodaj datoteke",
      update_media: "Posodobi datoteke",
      delete_media: "Izbriši datoteke",
      view_authorized_media: "Poglej datoteke pri pooblaščenih",
      create_authorized_media: "Dodaj datoteke pri pooblaščenih",
      update_authorized_media: "Posodobi datoteke pri pooblaščenih",
      delete_authorized_media: "Izbriši datoteke pri pooblaščenih",
      sign_as_organization: "Podpiši kot organizacija",
      sign_as_school: "Podpiši kot šola",
      sign_as_student: "Podpiši kot študent",
      evaluate_as_student: "Evalviraj kot študent",
      evaluate_as_mentor: "Evalviraj kot mentor",
      view_student_answers: "Poglej odgovore študenta",
      view_mentor_answers: "Poglej odgovore mentorja",
      set_as_pri_plan: "Nastavi datoteko kot načrt PRI",
      suggest: "Predlagaj",
      apply_for: "Prijava",
      create_notes: "Dodaj komentar",
      create_ratings: "Dodaj oceno",
      delete_notes: "Izbriši komentar",
      delete_ratings: "Izbriši oceno",
      mark_as_passed: "Označi kot opravljeno",
      update_notes: "Posodobi komentar",
      update_ratings: "Posodobit oceno",
      view_activity_log: "Poglej aktivnosti",
      view_notes: "Poglej komentarje",
      view_ratings: "Poglej ocene",
      vote_for: "Dodaj zanimanje",
      export: "Izvozi",
    }
  },

  filters: {
    save_current_filter: "Shrani trenuten filter",
    reset_current_filter: "Ponastavi filter",
    enter_filter_name: "Vnesi ime filtra",
    set_filter_as_default: "Nastavi kot privzet filter",
    reset_filter: "Ponastavi filter",
  },
  views: {
    login: {
      logout: "Odjava",
      login: "Prijava",
      agree: "Soglašam z vodenjem in anonimizirano obdelavo ter anonimiziranim posredovanjem podatkov Skupnosti VSŠ.",
      email: "E-naslov",
      password: "Geslo",
      please_agree: "Za nadaljevanje se morate soglašati z anonimno obdelavo podatkov",
      update_password: "Posodobi geslo",
      register: "Registracija",
      forgot_password: "Pozabljeno geslo",
      reset: "Ponastavi",
      lost_password: "Izgubljeno geslo",
      leave_empty_school: "V kolikor niste povezani z nobeno šolo, pustite prazno.",
      sign_in_you_in: "Prijava v teku. Prosimo počakajte...",
      try_again: "Prijava ni uspela. Prosimo poskusite ponovno.",
      agreement: "Privolitev",
      agreement_1: "PRIVOLITEV ZAPOSLENE/GA na višji šoli, članici Skupnosti višjih strokovnih šol Republike Slovenije, za obdelavo osebnih podatkov v zvezi z izobraževanjem.",
      agreement_2: "{name}, zaposlen/a na šoli {school},",
      agreement_3: "kot pedagoški kader",
      agreement_4: "na podlagi točke a prvega odstavka 6. člena Splošne uredbe (EU) o varstvu podatkov za namen informiranja in organiziranja izobraževanja ter za spremljanje izobraževanja dajem PRIVOLITEV:",
      agreement_5: "da šola z mano sodeluje po elektronski pošti ({email})",
      agreement_6: "da šola z mano sodeluje preko telefonske številke ({phone})",
      agreement_7: "da Skupnost VSŠ obdeluje anonimizirane podatke za namene analize potreb po izpopolnjevanjih in pripravo izobraževanj, prilagojenih višješolskim kadrom",
      agreement_8: "Pojasnilo:",
      agreement_9: "Privolitev se uporablja izključno za namen informiranja in organiziranja izobraževanja ter za spremljanje izobraževanja. Vaše podatke bomo hranili do preklica.",
      agreement_10: "Kot posameznik imate pod pogoji iz Splošne uredbe (EU) o varstvu podatkov pravico do seznanitve z lastnimi osebnimi podatki in omejitve obdelave ter do prenosljivosti, izbrisa in popravka podatkov.",
      agreement_11: "Dano privolitev lahko kadarkoli prekličete s pisno zahtevo, poslano po elektronski pošti na elektronski naslov Skupnosti višjih strokovnih šol Republike Slovenije: sekretariat{'@'}skupnost-vss-si.",
      agreement_12: "Za uveljavitev pravic na posamezni šoli se obrnite na e-naslov šole. V primeru kršitve varstva osebnih podatkov je možna pritožba/prijava pri Informacijskem pooblaščencu RS.",
      agreement_13: "Vaši podatki se zberejo in shranijo v aplikaciji »Evidenca izobraževanj; www.napredujem-visje.si), ki je v lasti Skupnosti VSŠ.",
    },
    profile: {
      voted_topics: "Teme, ki vas zanimajo",
      voted_courses: "Izobraževanja, ki vas zanimajo",
      applied_courses: "Prijave na izobraževanja",
      completed_courses: "Opravljena izobraževanja",
      applied_career_jobs: "Prijave na prosta delovna mesta",
      required_fields_are_marked: "Obvezna polja so označena z zvezdico. Ostala polja so neobvezna.",
      here_you_can_upload_cv: "Tukaj lahko naložite tudi prošnjo in življenjepis.",
      profile_updated: "Profil posodobljen",
      here_you_can_upload_certificate: "Tukaj lahko naložite certifikat.",
      add_completed_course: "Dodaj opravljeno izobraževanje",
      add_completed_course_below: "Vaša opravljena izobraževanja lahko dodate spodaj."
    },
    activity_log: {
      lbl: "Aktivnosti",
      created: "Ustvaril",
      updated: "Posodobil",
      unknown_causer: "Neznano",
    },
    statistics: {
      number_of_courses: "Število prijav na izobraževanja",
      work_years: "Delovna doba v letih",
      pedagogical_staff: "Pedagoški kader",
      non_pedagogical_staff: "Nepedagoški kader",
    },
    notes: {
      lbl: "Komentarji",
      enter: "Vnesi komentar",
      add: "Dodaj komentar",
      created: "Ustvaril",
      updated: "Posodobil",
    },
    assignments: {
      lbl: "Naloge",
      enter: "Vnesi nalogo",
      add: "Dodaj nalogo",
      save: "Shrani nalogo",
      log: "Dnevnik",
      enter_log: "Vnesi zapis",
      add_log: "Dodaj zapis v dnevnik",
      save_log: "Shrani dnevnik",
      created: "Ustvaril",
      updated: "Posodobil",
      no_assignments: "Ni dodanih nalog",
      no_assignment_logs: "Ni dodanih dnevnikov",
      assignment_deleted: "Naloga izbrisana",
      assignment_log_deleted: "Dnevnik izbrisan",
      delete_assignment: "Izbriši nalogo",
      delete_assignment_log: "Izbriši dnevnik",
    },
    notifications: {
      lbl: "Obvestila",
    },
    layout: {
      show_all: "Prikaži vse",
      select_language: "Izberi jezik",
      slovenian: "Slovenščina",
      english: "Angleščina",
    },
    table: {
      config: "Nastavi tabelo",
      visible_columns: "Vidni stolpci",
      export: "Izvoz",
      rows_per_page: "Število zapisov na stran:",
      no_data: "Ni zapisov",
    },
    form: {
      creating_new: "Novo: ",
      updating: "Posodobi: ",
    },
    internship_agreements: {
      sign: "Podpiši",
      check_answers: "Preveri odgovore",
      evaluate: "Evalviraj",
      evaluate_as_student: "Evalviraj (študent)",
      evaluate_as_mentor: "Evalviraj (mentor)",
      show_student_answers: "Prikaži odgovore študenta",
      show_mentor_answers: "Prikaži odgovore mentorja",
      signatures: "Podpisi",
      organization_signature: "Podpis organizacije",
      school_signature: "Podpis šole",
      student_signature: "Podpis študenta",
      grade_updated: "Ocena posodobljena",
      comment_updated: "Komentar posodobljen",
      signed: "Pogodba označena kot podpisana",
      answer_added: "Odgovor dodan",
      answer_updated: "Odgovor posodobljen",
      sign_as_student: "Označi kot podpisano s strani študenta",
      sign_as_school: "Označi kot podpisano s strani šole",
      sign_organization: "Označi kot podpisano s strani organizacije",
      no_study_module: "Kompetence brez modula",
      study_module_grade: "Skupna ocena modula",
      add_assignment: "Dodaj nalogo",
      assignment_description: "Opis naloge",
      add_assignment_log: "Dodaj dnevnik",
      save_grades: "Shrani ocene",
      competence_grade_updated: "Ocena kompetence posodobljena",
      organization_mentor_grade_updated: "Ocena mentorja organizacije posodobljena",
    },
    topics: {
      create: "Dodaj novo temo",
      update: "Posodobi temo",
      suggest: "Predlagaj temo",
      export_with_interests: "Izvoz tem vključno z zanimanji"
    },
    courses: {
      export_with_applications: "Izvoz izobraževanj s prijavami"
    },
    organizations: {
      suggest: "Predlagaj organizacijo",
    },
    users: {
      password_will_be_sent: "Povezava do gesla bo poslana na vnešen e-naslov!",
      add_appt_student: "Dodaj študenta",
      add_organization_mentor: "Dodaj mentorja v podjetju",
      add_school_mentor: "Dodaj šolskega organizatorja",
      add_school_admin: "Dodaj odgovorno osebo na šoli",
      add_organization_responsible_person: "Dodaj odgovorno osebo v podjetju",
      add_organization_contact_person: "Dodaj kontaktno osebo v podjetju",
      change_password_for_user: "Zamenjaj geslo uporabnika",
      users_updated: "Število posodobljenih uporabnikov",
      users_created: "Število novih uporabnikov",
      users_failed: "Število uporabnikov, ki se niso prenesli",
      import_from_evidenca: "Uvozi iz evidence",
      add_user: "Dodaj uporabnika",
      send_notification_mail: "Pošlji email sporočilo uporabniku z navodili za prijavo",
      notification_sent: "Obvestilo poslano",
    },
    internships: {
      internships_updated: "Število posodobljenih predlog PRI",
      internships_created: "Število novih predlog PRI",
      internships_failed: "Število predlog PRI, ki se niso prenesle",
    },
    study_programs: {
      study_programs_updated: "Število posodobljenih programov",
      study_programs_created: "Število novih programov",
      study_programs_failed: "Število programov, ki se niso prenesle",
    },
    calendar: {
      today: "Danes",
      next_month: "Naslednji mesec",
      previous_month: "Prejšnji mesec",
      months: {
        january: "Januar",
        february: "Februar",
        march: "Marec",
        april: "April",
        may: "Maj",
        june: "Junij",
        july: "Julij",
        august: "Avgust",
        september: "September",
        october: "Oktober",
        november: "November",
        december: "December",
      }
    },
    manual: {
      lbl: "Navodila",
      video_instructions: "Video navodila",
    },
    ratings: {
      no_ratings: "Ni ocen",
      add: "Dodaj oceno",
      enter_rate_value: "Prosimo izberite oceno",
      public: "Ocena in komentar sta lahko javno objavljena (vaše ime ne bo vidno)",
      is_public: "Ocena in komentar sta javna",
      rate_added: "Ocena oddana",
      your_rating: "Vaša ocena in komentar",
      average: "Povprečna ocena",
    },
    settings: {
      lbl: "Nastavitve",
      general: "Osnovno",
      connect_to_evidenca: "Povezava z evidenco",
      enable_ess_import: "Povezava z ESS (zavod za zaposlovanje)",
      night_refresh: "Vsakodnevno osveževanje (ponoči okoli 1. ure)",
      setting_updated: "Nastavitev posodobljena",
      disable_this_year: "Zakleni to leto"
    },
    career_jobs: {
      import_from_zzzrs: "Uvozi iz sistema Zavod za zaposlovanje RS"
    },
    prints: {
      end_element: "Konec odseka",
      start_element: "Začetek odseka"
    }
  },
  print: {
    title: "Apprenticeship completion report",
    has_from: "completed an apprenticeship from",
    to: "until",
    worked: "at",
    agreement_about: "The apprenticeship was agreed on in the apprenticeship agreement number ",
    supervised_by: "The apprenticeship was supervised by: ",
    student_completed: "The student completed",
    hours_of: "hours of practical work and gained the following competences:",
    school_organizer: "School organizer",
    disclosure: "* Additional information required: the school is required to have the following information for the yearly report: number of students in each year and module, list of PHEs, where the students completed their apprenticeships."
  },
  errors: {
    "Unauthenticated.": "Unauthenticated"
  },
  importer: {
    lbl: "Uvoz podatkov",
    import: "Uvozi",
    import_completed: "Uvoz končan",
    select_file: "Izberi datoteko",
    valid_files: "Dovoljen tip datoteke: XLSX",
    connect_fields: "Poveži polja",
    add_new: "Dodaj nove zapise",
    update_existing: "Posodobi obstoječe zapise",
    match_by_field: "Izberite polje po katerem naj se izvede iskanje:",
    first_line_in_file: "Prvi zapis iz datoteke",
  },
  statuses,
  models: {
    tables: {
      addresses: "Naslov | Naslovi",
      answers: "Odgovor | Odgovori",
      assignment_logs: "Dnevnik | Dnevniki",
      assignments: "Naloga | Naloge",
      settings: "Nastavitev | Nastavitve",
      signatures: "Podpis | Podpisi",
      statuses: "Status | Statusi",
      media: "Datoteka | Datoteke",
      notes: "Komentar | Komentarji",
      countries: "Država | Države",
      topic_user: "Tema - uporabnik | Teme - uporabniki",
      regions: "Regija | Regije",
      internship_agreement_study_module_grades: "Ocena modula na praktičnem izobraževanju | Ocena modulov na praktičnem izobraževanju",
      internship_agreement_dates: "Datum praktičnega izobraževanja | Datumi praktičnega izobraževanja",
      course_events: "Dogodek izobraževanja | Dogodki izobraževanj",
      course_user: "Uporabnik - izobraževanje | Uporabniki - izobraževanja",
      notifications: "Obvestilo | Obvestila",
      study_competences: "Študijska kompetenca | Študijske kompetence",
      study_modules: "Študijski modul | Študijski moduli",
      schools: "Šola | Šole",
      internships: "Predloge PRI | Predloge PRI",
      internship_agreements: "Sklenjena pogodba | Sklenjene pogodbe",
      study_programs: "Študijski program | Študijski programi",
      organizations: "Organizacija | Organizacije",
      template_articles: "Predloga členov | Predloge členov",
      template_questions: "Evalvacijski vprašalnik | Evalvacijski vprašalniki",
      users: "Uporabnik | Uporabniki",
      topics: "Tema | Teme",
      courses: "Izobraževanje | Izobraževanja",
      career_jobs: "Prosto delovno mesto | Prosta delovna mesta",
      career_job_types: "Tip zaposlitve | Tipi zaposlitev",
      roles: "Vloga | Vloge",
      permissions: "Pravica | Pravice",
      education_levels: "Stopnja izobrazbe | Stopnje izobrazbe",
      print_templates: "Predloga tiska | Predloga tiskanj",
      manuals: "Navodila | Navodila",
      partners: "Partner | Partnerji",
      questionnaires: "Vprašalnik | Vprašalniki",
      questionnaire_questions: "Vprašanje | Vprašanja",
      questionnaire_question_options: "Možnost odgovora | Možnosti odgovorov",
    },
    fields: {
      id: {
        lbl: "ID"
      },
      name: {
        lbl: "Naziv"
      },
      username: {
        lbl: "Uporabniško ime"
      },
      title: {
        lbl: "Naziv"
      },
      first_name: {
        lbl: "Ime"
      },
      last_name: {
        lbl: "Priimek"
      },
      email: {
        lbl: "E-naslov"
      },
      id_number: {
        lbl: "ID številka"
      },
      education_date: {
        lbl: "Datum izobraževanja"
      },
      subtitle: {
        lbl: "Podnaslov"
      },
      date_start: {
        lbl: "Datum začetka"
      },
      date_end: {
        lbl: "Datum konca"
      },
      date_due: {
        lbl: "Rok izvedbe"
      },
      user: {
        lbl: "Uporabnik"
      },
      date_finish: {
        lbl: "Datum zaključka PRI"
      },
      additional_information: {
        lbl: "Dodatne informacije"
      },
      grade_final: {
        lbl: "Zaključna ocena"
      },
      grade_exam: {
        lbl: "Ocena projektne/seminarske naloge"
      },
      grade_organization_mentor: {
        lbl: "Ocena mentorja v podjetju"
      },
      grade_school_mentor: {
        lbl: "Ocena organizatorja PRI"
      },
      display_name: {
        lbl: "Prikazno ime"
      },
      grade_expert_associate: {
        lbl: "Ocena strokovnega sodelavca"
      },
      grade_overall: {
        lbl: "Končna ocena"
      },
      option: {
        lbl: "Možnost",
        add: "Dodaj možnost odgovora",
      },
      question: {
        lbl: "Vprašanje",
        add: "Dodaj vprašanje",
      },
      ects_points: {
        lbl: "ECTS točke"
      },
      sum_of_hours: {
        lbl: "Skupno število ur"
      },
      document_number: {
        lbl: "Številka dokumenta"
      },
      description: {
        lbl: "Opis"
      },
      content: {
        lbl: "Vsebina"
      },
      created_by: {
        lbl: "Ustvaril",
      },
      career_job_type_id: {
        lbl: "ID tipa zaposlitve",
      },
      student_signature_id: {
        lbl: "ID podpisa s strani študenta",
      },
      organization_signature_id: {
        lbl: "ID podpisa s strani organizacije",
      },
      school_signature_id: {
        lbl: "ID podpisa s strani šole",
      },
      region_id: {
        lbl: "ID regije",
      },
      school_id: {
        lbl: "ID šole",
      },
      organization_mentor_questionnaire_id: {
        lbl: "ID vprašalnika za mentorja",
      },
      student_questionnaire_id: {
        lbl: "ID vprašalnika za študenta",
      },
      organization_responsible_person_id: {
        lbl: "ID odgovorne osebe v podjetju",
      },
      school_year: {
        lbl: "Šolsko leto",
      },
      study_year: {
        lbl: "Letnik",
      },
      student_id: {
        lbl: "ID študenta",
      },
      organization_mentor_id: {
        lbl: "ID mentorja v organizaciji",
      },
      school_mentor_id: {
        lbl: "ID šolskega mentorja",
      },
      organization_id: {
        lbl: "ID organizacije",
      },
      questionnaire_question_id: {
        lbl: "ID vprašanja",
      },
      internship_id: {
        lbl: "ID predloge PRI",
      },
      organization_contact_person_id: {
        lbl: "ID kontaktne osebe v organizaciji",
      },
      version: {
        lbl: "Verzija",
      },
      updated_by: {
        lbl: "Posodobil",
      },
      deleted_by: {
        lbl: "Izbrisal",
      },
      created_at: {
        lbl: "Ustvarjeno",
      },
      updated_at: {
        lbl: "Posodobljeno",
      },
      deleted_at: {
        lbl: "Izbrisano",
      },
      organization: {
        lbl: "Organizacija"
      },
      internship: {
        lbl: "Predloga PRI"
      },
      schoolMentor: {
        lbl: "Organizator PRI"
      },
      organizationMentor: {
        lbl: "Mentor organizacije"
      },
      organizationResponsiblePerson: {
        lbl: "Odgovorna oseba organizacije"
      },
      organizationContactPerson: {
        lbl: "Kontaktna oseba organizacije"
      },
      courseUsers: {
        lbl: "Prijave"
      },
      requirements: {
        lbl: "Pogoji"
      },
      offer: {
        lbl: "Ponudba"
      },
      private: {
        lbl: "Zasebno"
      },
      student: {
        lbl: "Študent/Dijak"
      },
      address1: {
        lbl: "Naslov 1"
      },
      address2: {
        lbl: "Naslov 2"
      },
      city: {
        lbl: "Kraj"
      },
      contractor: {
        lbl: "Izvajalec"
      },
      table_name: {
        lbl: "Ime tabele"
      },
      post: {
        lbl: "Pošta"
      },
      country: {
        lbl: "Država"
      },
      addresses: {
        lbl: "Naslov",
        lbl_plural: "Naslovi",
      },
      questionnaireQuestions: {
        lbl: "Vprašanje",
        lbl_plural: "Vprašanja",
      },
      questionnaireQuestionOptions: {
        lbl: "Možnost odgovora",
        lbl_plural: "Možnosti odgovorov",
      },
      type: {
        lbl: "Tip",
      },
      studyProgram: {
        lbl: "Študijski program"
      },
      studyModules: {
        lbl: "Študijski modul",
        lbl_plural: "Študijski moduli"
      },
      studyCompetences: {
        lbl: "Študijska kompetenca",
        lbl_plural: "Študijske kompetence"
      },
      studentQuestionnaire: {
        lbl: "Evalvacijski vprašalnik za študenta"
      },
      organizationQuestionnaire: {
        lbl: "Evalvacijski vprašalnik za delodajalca"
      },
      organizationMentorQuestionnaire: {
        lbl: "Evalvacijski vprašalnik za mentorja v podjetju"
      },
      study_competences: {
        lbl: "Študijske kompetence"
      },
      grades: {
        lbl: "Ocene"
      },
      avatar: {
        lbl: "Prikazna slika"
      },
      full_url: {
        lbl: "Povezava"
      },
      video_link: {
        lbl: "Povezava do videa"
      },
      mentor_report: {
        lbl: "Poročilo mentorja"
      },
      internshipAgreementDates: {
        lbl_plural: "Datumi PRI",
        lbl: "Datum"
      },
      student_report: {
        lbl: "Poročilo študenta"
      },
      level: {
        lbl: "Stopnja"
      },
      articles: {
        lbl: "Členi pogodbe"
      },
      notes: {
        lbl: "Komentarji"
      },
      school: {
        lbl: "Šola"
      },
      assignments: {
        lbl: "Naloga",
        lbl_plural: "Naloge"
      },
      activities: {
        lbl: "Aktivnosti"
      },
      primary: {
        lbl: "Glavni"
      },
      primary_address: {
        lbl: "Glavni naslov"
      },
      address: {
        lbl: "Naslov"
      },
      date_birthday: {
        lbl: "Rojstni datum"
      },
      phone: {
        lbl: "Telefon"
      },
      mobile: {
        lbl: "Mobitel"
      },
      vat_id: {
        lbl: "Davčna številka"
      },
      ssn: {
        lbl: "EMŠO"
      },
      password: {
        lbl: "Geslo"
      },
      password_confirmation: {
        lbl: "Ponovi geslo"
      },
      old_password: {
        lbl: "Trenutno geslo"
      },
      profile: {
        lbl: "Profil"
      },
      roles: {
        lbl: "Vloge"
      },
      permissions: {
        lbl: "Pravice"
      },
      date: {
        lbl: "Datum"
      },
      date_completed: {
        lbl: "Datum opravljanja"
      },
      topic: {
        lbl: "Tema"
      },
      course: {
        lbl: "Izobraževanje"
      },
      online: {
        lbl: "Preko spleta"
      },
      available_in_online_classes: {
        lbl: "Učne vsebine za potrebe samostojnega učenja strokovnih delavcev so na razpolago v spletnih učilnicah institucije izvajalke programa."
      },
      ects: {
        lbl: "ECTS točke"
      },
      total_hours: {
        lbl: "Število ur"
      },
      external_url: {
        lbl: "Zunanja povezava"
      },
      courseEvents: {
        lbl: "Datum izobraževanja",
        lbl_plural: "Datumi izobraževanj",
      },
      votes: {
        lbl: "Glasovi"
      },
      term: {
        lbl: "Željen termin"
      },
      schools: {
        lbl: "Šole"
      },
      partners: {
        lbl: "Partnerji"
      },
      educational_organizations: {
        lbl: "Izobraževalne organizacije"
      },
      duration: {
        lbl: "Trajanje (v minutah)"
      },
      field_of_work: {
        lbl: "Področje dela"
      },
      careerJobType: {
        lbl: "Tip zaposlitve"
      },
      remote: {
        lbl: "Izvedba na daljavo"
      },
      from_home: {
        lbl: "Delo od doma"
      },
      date_apply: {
        lbl: "Prijave do"
      },
      region: {
        lbl: "Regija"
      },
      locked: {
        lbl: "Zaklenjeno"
      },
      group: {
        lbl: "Skupina"
      },
      nice_name: {
        lbl: "Olepšan naziv"
      },
      show_on_registration_form: {
        lbl: "Prikaži na obrazcu za registracijo"
      },
      application: {
        lbl: "Aplikacija"
      },
      web_page: {
        lbl: "Spletna stran"
      },
      registration_number: {
        lbl: "Matična številka"
      },
      user_registration_number: {
        lbl: "Vpisna številka"
      },
      register_as: {
        lbl: "Registriraj se kot"
      },
      select_school: {
        lbl: "Izberi šolo"
      },
      url_facebook: {
        lbl: "Facebook povezava"
      },
      url_instagram: {
        lbl: "Instagram povezava"
      },
      url_twitter: {
        lbl: "Twitter povezava"
      },
      users: {
        lbl: "Uporabniki",
        lbl_plural: "Uporabniki"
      },
      users_in_organization: {
        lbl: "Osebe v organizaciji"
      },
      gender: {
        lbl: "Spol"
      },
      sort_order: {
        lbl: "Vrstni red"
      },
      work_position: {
        lbl: "(Željeno) delovno mesto"
      },
      year_of_initial_employment: {
        lbl: "Leto prve zaposlitve"
      },
      completed_pedagogical_education: {
        lbl: "Pedagoški kader"
      },
      adult_education: {
        lbl: "Poučevanje odraslih"
      },
      private_school: {
        lbl: "Zasebna šola"
      },
      applications: {
        lbl: "Prijave"
      },
      ratings: {
        lbl: "Ocene"
      },
      interests: {
        lbl: "Zanimanja"
      },
      educationLevel: {
        lbl: "Stopnja izobrazbe"
      },
      studyPrograms: {
        lbl: "Študijski programi"
      },
      status: {
        lbl: "Status"
      },
      actions: {
        lbl: "Akcije"
      },
      evidenca_id: {
        lbl: "ID - Evidenca"
      },
      files: {
        lbl: "Datoteke"
      },
      dateFrom: {
        lbl: "Od"
      },
      dateTo: {
        lbl: "Do"
      },
      cv: {
        lbl: "CV"
      },
      email_verified_at: {
        lbl: "Elektronski naslov preverjen na dan"
      },
      question_type: {
        lbl: "Tip vprašanja"
      },
      number_of_applications: {
        lbl: "Število prijav"
      },
      number_of_interests: {
        lbl: "Število zanimanj"
      },
      contact: {
        lbl: "Kontakt (telefon, elektronski naslov...)"
      },
      external_link: {
        lbl: "Zunanja povezava"
      },
      code: {
        lbl: "Šifra"
      },
      study_modules: {
        lbl: "Študijski moduli"
      },
      event: {
        lbl: "Izobraževanje"
      }
    }
  },
  uploader: {
    upload_files: "Izberite datoteke za nalaganje",
    upload_certificates: "Naložite certifikat",
    uploaded_files: "Naložene datoteke",
    no_files: "Ni naloženih datotek",
  },
  print_templates: {
    reserved_words: "Rezervirane besede",
    reserved_words_help: "Vstavite rezervirano besedo s pomočjo gumba za dodajanje ali uporabite gumb za kopiranje in prilepite na željeno mesto."
  }
};
