import {
  SiteStateFilterInterface,
  SiteStateFormInterface,
  SiteStateInterface,
  SiteStateRowsPerPageInterface,
  SiteStateSortColumnsInterface,
  SiteStateVisibleColumnsInterface
} from "src/store/site/types";

export function language(state: SiteStateInterface): string {
  return state.language
}

export function filters(state: SiteStateInterface): SiteStateFilterInterface {
  return state.filters
}

export function defaultFilters(state: SiteStateInterface): SiteStateFilterInterface {
  return state.defaultFilters
}

export function visibleColumns(state: SiteStateInterface): SiteStateVisibleColumnsInterface {
  return state.visibleColumns
}

export function defaultVisibleColumns(state: SiteStateInterface): SiteStateVisibleColumnsInterface {
  return state.defaultVisibleColumns
}

export function sortColumns(state: SiteStateInterface): SiteStateSortColumnsInterface {
  return state.sortColumns
}

export function defaultSortColumns(state: SiteStateInterface): SiteStateSortColumnsInterface {
  return state.defaultSortColumns
}

export function rowsPerPage(state: SiteStateInterface): SiteStateRowsPerPageInterface {
  return state.rowsPerPage
}

export function forms(state: SiteStateInterface): { [key: string]: SiteStateFormInterface } {
  return state.forms
}
