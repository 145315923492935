import { RouteRecordRaw } from "vue-router";
import routesCareerCenter from "src/router/routes-career-center";
import routesEvidenca from "src/router/routes-evidenca";
import routesAppt from "src/router/routes-appt";
import routesCommon from "src/router/routes-common";

let routes: RouteRecordRaw[] = routesCommon;

// noinspection DuplicatedCode
if (process.env.IS_APPRENTICEHSIP_TRACK === "true") {
  routes = routes.concat(routesAppt)
}

if (process.env.IS_EVIDENCA === "true") {
  routes = routes.concat(routesEvidenca)
}

if (process.env.IS_CAREER_CENTER === "true") {
  routes = routes.concat(routesCareerCenter)
}

export default routes;
