import { route } from "quasar/wrappers";
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";
import routes from "./routes";

import { StateInterface } from "src/store/types";
import { UserStateInterface } from "src/store/auth/types";
import useAbility from "src/hooks/UseAbility";

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

// @ts-ignore
export default route<StateInterface>(({ store }) => {
  const createHistory =
    process.env.SERVER
      ? createMemoryHistory
      : process.env.VUE_ROUTER_MODE === "history"
        ? createWebHistory
        : createWebHashHistory;

  const Router = createRouter({
    scrollBehavior: () => ({
      left: 0,
      top: 0
    }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(
      process.env.MODE === "ssr" ? void 0 : process.env.VUE_ROUTER_BASE
    ),
  });

  Router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {

    const { permissionAsString } = useAbility();

    if (!to.meta.requireAuth) {
      next()
      return;
    }

    if (!store.getters["auth/isSignedIn"]) {
      void Router.push("login")
      return;

    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const user: UserStateInterface = store.getters["auth/user"];

    if (!to.meta.permission) {
      next()
      return;
    }

    let permissions = to.meta.permission;
    if (!Array.isArray(permissions)) {
      permissions = [permissions]
    }

    for (const permissionObject of permissions) {

      const permission = permissionAsString(permissionObject)

      if (user.allPermissions.map(singlePermission => singlePermission.name).includes(permission)) {
        next()
        return;
      }
    }
    void Router.push(from.path + "?permission_denied=1")
    return;
  })

  return Router;
});
