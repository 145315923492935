import type { ApolloClientOptions } from "@apollo/client/core"
import { createHttpLink, InMemoryCache } from "@apollo/client/core"

/* import type { BootFileParams } from '@quasar/app' */
const getHeaders = () => {

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-argument
  const vuexData = JSON.parse(localStorage.vuex ?? null);
  const headers = {
    Authorization: null,
    accept: "application/json"
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const token: string = vuexData?.auth?.token ?? ""
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  return headers
}

export /* async */ function getClientOptions(/* {app, router, ...}: Partial<BootFileParams<unknown>> */) {
  return <ApolloClientOptions<unknown>>Object.assign(
    // General options.
    <ApolloClientOptions<unknown>>{
      link: createHttpLink({
        uri:
          process.env.GRAPHQL_URI ||
          // Change to your graphql endpoint.
          "https://example.com/graphql",
        headers: getHeaders()
      }),

      cache: new InMemoryCache({}),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "no-cache",
        },
        query: {
          fetchPolicy: "no-cache",
        },
      },
    },

    // Specific Quasar mode options.
    process.env.MODE === "spa"
      ? {
        //
      }
      : {},
    process.env.MODE === "ssr"
      ? {
        //
      }
      : {},
    process.env.MODE === "pwa"
      ? {
        //
      }
      : {},
    process.env.MODE === "bex"
      ? {
        //
      }
      : {},
    process.env.MODE === "cordova"
      ? {
        //
      }
      : {},
    process.env.MODE === "capacitor"
      ? {
        //
      }
      : {},
    process.env.MODE === "electron"
      ? {
        //
      }
      : {},

    // dev/prod options.
    process.env.DEV
      ? {
        //
      }
      : {},
    process.env.PROD
      ? {
        //
      }
      : {},

    // For ssr mode, when on server.
    process.env.MODE === "ssr" && process.env.SERVER
      ? {
        ssrMode: true,
      }
      : {},
    // For ssr mode, when on client.
    process.env.MODE === "ssr" && process.env.CLIENT
      ? {
        ssrForceFetchDelay: 100,
      }
      : {}
  )
}
