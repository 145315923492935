import { AuthStateInterface, UserFiltersInterface, UserStateInterface } from "src/store/auth/types";

export function isSignedIn(state: AuthStateInterface): boolean {
  return !!state.token
}

export function token(state: AuthStateInterface): string {
  return state.token
}

export function user(state: AuthStateInterface): UserStateInterface {
  return state.user
}

export function filters(state: AuthStateInterface): UserFiltersInterface {
  return state.userSettings.filters
}

export function language(state: AuthStateInterface): string {
  return state.userSettings.language
}

export function currencyCode(state: AuthStateInterface): string {
  return state.userSettings.currencyCode
}

export function dateFormat(state: AuthStateInterface): string {
  return state.userSettings.dateFormat
}
