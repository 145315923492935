// noinspection AllyPlainJsInspection

export default {
  null: {
    lbl: "Brez statusa",
    color: "blue"
  },
  new: {
    lbl: "Novo",
    color: "blue"
  },
  waiting_for_confirmation: {
    lbl: "Čakanje na potrditev",
    color: "primary"
  },
  applications_opened: {
    lbl: "Prijave odprte",
    color: "orange"
  },
  in_preparation: {
    lbl: "V pripravi",
    color: "blue"
  },
  pending: {
    lbl: "V pripravi",
    color: "blue"
  },
  unverified: {
    lbl: "Nepreverjeno in neaktivno",
    color: "red"
  },
  course_canceled: {
    lbl: "Izobraževanje odpovedano",
    color: "red"
  },
  course_completed: {
    lbl: "Izobraževanje zaključeno",
    color: "grey"
  },
  apply_finished: {
    lbl: "Prijave zaključene",
    color: "green"
  },
  confirmed: {
    lbl: "Potrjeno",
    color: "green"
  },
  pass: {
    lbl: "Opravljeno",
    color: "green"
  },
  fail: {
    lbl: "Neopravljeno",
    color: "red"
  },
  completed: {
    lbl: "Končano",
    color: "warning"
  },
  no_status: {
    lbl: "Brez statusa",
    color: "grey"
  },
  suggested: {
    lbl: "Predlagano",
    color: "orange"
  },
  denied: {
    lbl: "Zavrnjeno",
    color: "red"
  },
  canceled: {
    lbl: "Preklicano",
    color: "red"
  },
  active: {
    lbl: "Aktivno",
    color: "green"
  },
  in_progress: {
    lbl: "V teku",
    color: "green"
  },
  not_active: {
    lbl: "Neaktivno",
    color: "red"
  },
  expired: {
    lbl: "Poteklo",
    color: "red"
  },
  other: {
    lbl: "Drugo",
    color: "blue"
  }
}
