import { boot } from "quasar/wrappers";
import { createI18n } from "vue-i18n";

import messages from "src/i18n";
import datetimeFormats from "src/i18n/dateTimeFormats";

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const vuexData = JSON.parse(localStorage.vuex ?? null);

const i18n = createI18n({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  locale: vuexData?.site?.language ?? "sl-SI",
  fallbackLocale: "sl-SI",
  silentTranslationWarn: true,
  messages,
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
  datetimeFormats,
});

export default boot(({ app }) => {
  // Set i18n instance on app
  app.use(i18n);
});

export { i18n };
