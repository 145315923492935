import { RouteRecordRaw } from "vue-router";

const routesAppt: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/IndexAppt.vue")
      },
    ],
    meta: {
      requireAuth: true,
      permission: null,

    }
  },
  {
    path: "/set-year",
    component: () => import("layouts/EmptyLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/LoginSetYear.vue")
      },
    ],
    meta: {
      requireAuth: true,
      permission: null,
    }
  },
  {
    path: "/organizations",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/organizations/index.vue")
      },
      {
        path: "/organizations/create",
        component: () => import("pages/organizations/view.vue"),
        meta: {

          permission: {
            action: "create",
            model: "organizations"
          }
        }
      },
      {
        path: "/organizations/:id",
        component: () => import("pages/organizations/view.vue"),
        props: true,
        meta: {

          permission: {
            action: "view",
            model: "organizations"
          }
        }
      },
      {
        path: "/organizations/suggest",
        component: () => import("pages/organizations/suggest.vue"),
        meta: {
          permission: {
            action: "suggest",
            model: "organizations"
          }
        }
      },
      {
        path: "/organizations/:id/duplicate",
        component: () => import("pages/organizations/view.vue"),
        props: { duplicate: true },
        meta: {
          permission: {
            action: "create",
            model: "organizations"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "organizations"
      }
    }
  },
  {
    path: "/schools",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/schools/index.vue")
      },
      {
        path: "/schools/create",
        component: () => import("pages/schools/view.vue"),
        meta: {
          permission: {
            action: "create",
            model: "schools"
          }
        }
      },
      {
        path: "/schools/:id",
        component: () => import("pages/schools/view.vue"),
        props: true,
        meta: {

          permission: {
            action: "view",
            model: "schools"
          }
        }
      },
      {
        path: "/schools/:id/duplicate",
        component: () => import("pages/schools/view.vue"),
        props: { duplicate: true },
        meta: {

          permission: {
            action: "view",
            model: "schools"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "schools"
      }
    }
  },
  {
    path: "/internships",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/internships/index.vue"),
      },
      {
        path: "/internships/create",
        component: () => import("pages/internships/view.vue"),
        meta: {

          permission: {
            action: "create",
            model: "internships"
          }
        }
      },
      {
        path: "/internships/:id",
        component: () => import("pages/internships/view.vue"),
        props: true,
        meta: {

          permission: {
            action: "view",
            model: "internships"
          }
        }
      },
      {
        path: "/internships/:id/duplicate",
        component: () => import("pages/internships/view.vue"),
        props: { duplicate: true },
        meta: {

          permission: {
            action: "create",
            model: "internships"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,

      permission: {
        action: "view",
        model: "internships"
      }
    }
  },
  {
    path: "/internship-agreements",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/internship-agreements/index.vue")
      },
      {
        path: "/internship-agreements/create",
        component: () => import("pages/internship-agreements/view.vue"),
        meta: {
          permission: {
            action: "create",
            model: "internship_agreements"
          }
        }
      },
      {
        path: "/internship-agreements/:id",
        component: () => import("pages/internship-agreements/view.vue"),
        props: true,
        meta: {
          permission: [{
            action: "view",
            model: "internship_agreements"
          }, {
            action: "view_authorized",
            model: "internship_agreements"
          }]
        }
      },
      {
        path: "/internship-agreements/:id/duplicate",
        component: () => import("pages/internship-agreements/view.vue"),
        props: { duplicate: true },
        meta: {
          permission: {
            action: "create",
            model: "internship_agreements"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,
      permission: [{
        action: "view",
        model: "internship_agreements"
      }, {
        action: "view_authorized",
        model: "internship_agreements"
      }]
    }
  },
  {
    path: "/study-programs",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/study-programs/index.vue")
      },
      {
        path: "/study-programs/create",
        component: () => import("pages/study-programs/view.vue"),
        meta: {

          permission: {
            action: "create",
            model: "study_programs"
          }
        }
      },
      {
        path: "/study-programs/:id",
        component: () => import("pages/study-programs/view.vue"),
        props: true,
        meta: {

          permission: {
            action: "view",
            model: "study_programs"
          }
        }
      },
      {
        path: "/study-programs/:id/duplicate",
        component: () => import("pages/study-programs/view.vue"),
        props: { duplicate: true },
        meta: {
          permission: {
            action: "create",
            model: "study_programs"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "study_programs"
      }
    }
  },
  {
    path: "/template-articles",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/template-articles/index.vue")
      },
      {
        path: "/template-articles/create",
        component: () => import("pages/template-articles/view.vue"),
        meta: {
          permission: {
            action: "create",
            model: "template_articles"
          }
        }
      },
      {
        path: "/template-articles/:id",
        component: () => import("pages/template-articles/view.vue"),
        props: true,
        meta: {
          permission: {
            action: "view",
            model: "template_articles"
          }
        }
      },
      {
        path: "/template-articles/:id/duplicate",
        component: () => import("pages/template-articles/view.vue"),
        props: { duplicate: true },
        meta: {
          permission: {
            action: "create",
            model: "template_articles"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "template_articles"
      }
    }
  },
  {
    path: "/questionnaires",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/questionnaires/index.vue")
      },
      {
        path: "/questionnaires/create",
        component: () => import("pages/questionnaires/view.vue"),
        meta: {
          permission: {
            action: "create",
            model: "questionnaires"
          }
        }
      },
      {
        path: "/questionnaires/:id",
        component: () => import("pages/questionnaires/view.vue"),
        props: true,
        meta: {
          permission: {
            action: "view",
            model: "questionnaires"
          }
        }
      },
      {
        path: "/questionnaires/:id/duplicate",
        component: () => import("pages/questionnaires/view.vue"),
        props: { duplicate: true },
        meta: {
          permission: {
            action: "create",
            model: "questionnaires"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "questionnaires"
      }
    }
  },
  {
    path: "/questionnaire-questions",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/questionnaire-questions/index.vue")
      },
      {
        path: "/questionnaire-questions/create",
        component: () => import("pages/questionnaire-questions/view.vue"),
        meta: {
          permission: {
            action: "create",
            model: "questionnaire_questions"
          }
        }
      },
      {
        path: "/questionnaire-questions/:id",
        component: () => import("pages/questionnaire-questions/view.vue"),
        props: true,
        meta: {
          permission: {
            action: "view",
            model: "questionnaire_questions"
          }
        }
      },
      {
        path: "/questionnaire-questions/:id/duplicate",
        component: () => import("pages/questionnaire-questions/view.vue"),
        props: { duplicate: true },
        meta: {
          permission: {
            action: "create",
            model: "questionnaire_questions"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "questionnaire_questions"
      }
    }
  },
  {
    path: "/questionnaire-question-options",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/questionnaire-question-options/index.vue")
      },
      {
        path: "/questionnaire-question-options/create",
        component: () => import("pages/questionnaire-question-options/view.vue"),
        meta: {
          permission: {
            action: "create",
            model: "questionnaire_question_options"
          }
        }
      },
      {
        path: "/questionnaire-question-options/:id",
        component: () => import("pages/questionnaire-question-options/view.vue"),
        props: true,
        meta: {
          permission: {
            action: "view",
            model: "questionnaire_question_options"
          }
        }
      },
      {
        path: "/questionnaire-question-options/:id/duplicate",
        component: () => import("pages/questionnaire-question-options/view.vue"),
        props: { duplicate: true },
        meta: {
          permission: {
            action: "create",
            model: "questionnaire_question_options"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "questionnaire_question_options"
      }
    }
  },

  {
    path: "/register",
    name: "register",
    component: () => import("layouts/EmptyLayout.vue"),
    children: [{
      path: "",
      component: () => import("pages/Register.vue")
    }],
  },
  {
    path: "/email-verify",
    name: "email-verify",
    component: () => import("layouts/EmptyLayout.vue"),
    children: [{
      path: "",
      component: () => import("pages/VerifyEmail.vue")
    }],
  },
];

export default routesAppt;
