import { AuthStateInterface } from "src/store/auth/types";

export default function (): AuthStateInterface {
  return {
    token: null,
    user: null,
    userSettings: {
      filters: {},
      language: null,
      currencyCode: null,
      dateFormat: null
    }
  }
}
