import { UserStateInterface } from "src/store/auth/types";
import { Commit } from "vuex";
import { FilterInterface } from "src/store/types";

export function setToken({ commit }: { commit: Commit }, token: string) {
  commit("setToken", token)
}

export function setUser({ commit }: { commit: Commit }, user: UserStateInterface) {
  commit("setUser", user)
  if (user.settings) {
    const filters = user.settings.find(setting => setting.key === "filters");
    if (filters) {
      for (const [key, value] of Object.entries(filters.data)) {
        commit("saveFilters", { filterKey: key, filters: value })
      }
    }
  }
}

export function saveFilter(
  { commit }: { commit: Commit },
  {
    filterKey,
    filter,
    name,
    visibleColumns,
    sortColumns,
  }: { filterKey: string, filter: FilterInterface[], name: string, visibleColumns: string[], sortColumns: string[] }
) {
  commit("saveFilter", { filterKey, filter, name, visibleColumns, sortColumns })
}

export function deleteFilter(
  { commit }: { commit: Commit },
  { filterKey, filterId }: { filterKey: string, filterId: string }
) {
  commit("deleteFilter", { filterKey, filterId })
}

export function logout({ commit }: { commit: Commit }) {
  commit("setToken", null)
  commit("setUser", null)
}

